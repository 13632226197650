import React from 'react';

import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';

import { statusMap } from '../Status/Status';

const loadingStatusIconsMap = {
  success: <Icon color={statusMap.success.color} name="success" size="18px" />,
  unknown: <Icon name="question" size="18px" />,
  warning: <Icon name="warning" size="18px" />,
};

export interface LoadingStatusProps {
  status?: keyof typeof loadingStatusIconsMap;
  text?: string;
}

const LoadingStatus: React.FC<LoadingStatusProps> = ({ status = 'success', text }) => {
  if (!text) {
    return null;
  }

  return (
    <Tooltip content={text}>
      <span style={{ cursor: 'default' }}>{loadingStatusIconsMap[status]}</span>
    </Tooltip>
  );
};

export default LoadingStatus;

import React from 'react';

import Box, { StyledBoxProps } from '@components/Box';
import Button from '@components/Button/Button';
import Tooltip from '@components/Tooltip';
import Icon, { IconProps } from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';
import { GetColorProps } from '@styles/mixins/getColor';

export interface FiltersListFilterType {
  color?: GetColorProps;
  count?: number;
  dataTypes?: DataTypesModel;
  iconProps?: IconProps;
  label?: string;
  name: string;
  startIcon?: React.ReactNode;
}

export interface FiltersListProps<T> extends Pick<StyledBoxProps, 'mb'> {
  data: T[];
  onClick: (filter: T) => void;
  selectedFilter: T | null;
}

const FiltersList = <T extends FiltersListFilterType>({
  data,
  mb,
  onClick,
  selectedFilter,
}: FiltersListProps<T>) => {
  return (
    <Box compDisplay="flex" flexWrap="wrap" gap={0.5} mb={mb ?? 1}>
      {data
        ?.filter((filter) => (filter.count ?? 0) > 0 || filter.name === 'all')
        .map((filter) => {
          const { color, count, dataTypes, label, name, startIcon } = filter;
          const hasCount = count !== undefined;

          return (
            <Button
              key={name}
              aria-pressed={filter.name === selectedFilter?.name}
              backgroundColor={filter.name === selectedFilter?.name ? 'gray.100' : 'white'}
              borderRadius="50px"
              color={color ?? 'gray.700'}
              compSize="sm"
              endIcon={
                hasCount && (
                  <Box alignItems="center" color="gray.500" compDisplay="flex" gap={0.25}>
                    <span>{count}</span>
                  </Box>
                )
              }
              fontWeight="medium"
              name={name}
              onClick={() => onClick(filter)}
              spacing={0.5}
              startIcon={
                (hasCount || dataTypes || startIcon) && (
                  <Box alignItems="center" compDisplay="flex" gap={0.25}>
                    {startIcon}
                    {dataTypes && (
                      <>
                        <Tooltip content={dataTypes.tooltips.dataSource}>
                          <Icon name={dataTypes.icons.dataSource} size="18px" />
                        </Tooltip>
                        <Tooltip content={dataTypes.tooltips.objectType}>
                          <Icon name={dataTypes.icons.objectType} size="18px" />
                        </Tooltip>
                      </>
                    )}
                  </Box>
                )
              }
              variant="outlined"
            >
              {label}
            </Button>
          );
        })}
    </Box>
  );
};

export default FiltersList;

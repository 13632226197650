import React, { useState } from 'react';

import { useFetchTags } from '@api/tags';
import { TagModel, TagType } from '@api/tags/TagModel';
import Box from '@components/Box';
import IconButton from '@components/IconButton';
import PageHeaderBar from '@components/PageHeaderBar';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import theme from '@styles/theme';
import { setParams } from '@utils/filters';
import MetadataDecorator from '@utils/MetadataDecorator';
import stripSpaces from '@utils/stripSpaces';

import { defaultCategoryTagConfig, defaultDbtTagConfig, defaultStatusTagConfig } from './filters';
import { StyledGridContainer } from './TagsPage.styles';
import TagsSection from './TagsSection';

const query = stripSpaces(`{
  guid,
  type,
  name,
  icon,
  color,
  owner,
  count,
  description,
  richtext_description,
}`);

const calcTotalPages = (count?: number) => {
  if (!count || !defaultDbtTagConfig.page_size) {
    return 1;
  }

  return Math.ceil(count / defaultDbtTagConfig.page_size);
};

const TagsPage: React.FC = () => {
  const { hasDataSource, hasEditPermissions } = useUserContext();
  const { MODAL_IDS, openModal } = useModal();
  const [categoryTagPageNumber, setCategoryTagPageNumber] = useState<number>(1);
  const [statusTagPageNumber, setStatusTagPageNumber] = useState<number>(1);
  const [dbtTagPageNumber, setDbtTagPageNumber] = useState<number>(1);
  const canEdit = hasEditPermissions ?? false;

  const {
    data: categoryTagsData,
    isLoading: categoryTagsLoading,
    refetch: refetchCategoryTags,
  } = useFetchTags({
    keepPreviousData: true,
    params: {
      ...setParams(defaultCategoryTagConfig),
      page: categoryTagPageNumber,
      query,
      top_level: true,
    },
  });

  const {
    data: statusTagsData,
    isLoading: statusTagsLoading,
    refetch: refetchStatusTags,
  } = useFetchTags({
    keepPreviousData: true,
    params: {
      ...setParams(defaultStatusTagConfig),
      page: statusTagPageNumber,
      query,
      top_level: true,
    },
  });

  const { data: dbtTagsData, isLoading: dbtTagsLoading } = useFetchTags({
    keepPreviousData: true,
    params: {
      ...setParams(defaultDbtTagConfig),
      page: dbtTagPageNumber,
      query,
      top_level: true,
    },
  });

  const handleCreateTag = () => {
    refetchStatusTags();
    refetchCategoryTags();
  };

  const handleClickAdd = (type: TagType = 'category') => {
    openModal(MODAL_IDS.createTag, { initialState: { type }, onCreateTag: handleCreateTag });
  };

  const handleClickEdit = (tag: TagModel) => {
    openModal(MODAL_IDS.createTag, {
      initialState: tag,
      isEdit: true,
      onCreateTag: handleCreateTag,
    });
  };

  const handleClickDelete = (tag: TagModel) => {
    openModal(MODAL_IDS.deleteTag, { item: tag });
  };

  return (
    <>
      <MetadataDecorator title="Tags" />
      <StyledGridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <Box compDisplay="flex" flexDirection="column">
          <Box mb={4}>
            <div className="main-header">
              <PageHeaderBar
                title="Tags"
                titleToolBox={
                  canEdit && (
                    <IconButton label="New Tag" onClick={() => handleClickAdd()}>
                      <Icon color={theme.colors.gray[500]} name="plus" size="20px" />
                    </IconButton>
                  )
                }
              />
            </div>
          </Box>
          <TagsSection
            canEdit={canEdit}
            loading={categoryTagsLoading}
            onClickAdd={() => handleClickAdd('category')}
            onClickDelete={handleClickDelete}
            onClickEdit={handleClickEdit}
            onTagPageChange={setCategoryTagPageNumber}
            tags={categoryTagsData?.results}
            title="Category Tags"
            totalPages={calcTotalPages(categoryTagsData?.count)}
            type="category"
          />
          <TagsSection
            canEdit={canEdit}
            loading={statusTagsLoading}
            onClickAdd={() => handleClickAdd('status')}
            onClickDelete={handleClickDelete}
            onClickEdit={handleClickEdit}
            onTagPageChange={setStatusTagPageNumber}
            tags={statusTagsData?.results}
            title="Status Tags"
            totalPages={calcTotalPages(statusTagsData?.count)}
            type="status"
          />
          {hasDataSource({ type: 'dbt' }) && (dbtTagsData?.count ?? 0) > 0 && (
            <TagsSection
              canEdit={false}
              loading={dbtTagsLoading}
              onClickAdd={undefined} // dbt tags cannot be created, removed, or editted outside their owners
              onClickDelete={() => {}}
              onClickEdit={handleClickEdit}
              onTagPageChange={setDbtTagPageNumber}
              tags={dbtTagsData?.results}
              title="dbt Tags"
              totalPages={calcTotalPages(dbtTagsData?.count)}
              type="dbt"
            />
          )}
        </Box>
      </StyledGridContainer>
    </>
  );
};

export default TagsPage;

import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Cell } from 'react-table';

import { useFetchDataQualityUpstreamIssues } from '@api/dataQuality';
import { DataQualityTestModel } from '@api/dataQuality/DataQualityTestModel';
import { GetDataQualityUpstreamIssuesListPathParams } from '@api/openapi.generated';
import { StatusType } from '@api/types';
import Box from '@components/Box';
import DateTime from '@components/DateTime';
import EmptyContent from '@components/EmptyContent';
import ImageMessage from '@components/ImageMessage';
import OpenInNewTabButton from '@components/OpenInNewTabButton/OpenInNewTabButton';
import Status from '@components/Status/Status';
import TabError from '@components/TabContent/TabError';
import DataSourceTypeCell from '@components/Table/Cells/DataSourceTypeCell';
import LineageDistanceCell from '@components/Table/Cells/LineageDistanceCell';
import LinkedCell, { TableItem } from '@components/Table/Cells/LinkedCell';
import MonitorColumnCell from '@components/Table/Cells/MonitorColumnCell';
import LineageDistanceHeader from '@components/Table/Headers/LineageDistanceHeader';
import Table from '@components/Table/Table';
import { sortDates } from '@components/Table/Table/sortDates';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useMetadataObjectContext } from '@context/MetadataObject';
import { useUserContext } from '@context/User';
import ColumnPage from '@pages/ColumnPage';
import { Filter } from '@utils';
import wrapString from '@utils/wrapString';

export type MonitorsStatusCountsType = Record<StatusType, number> | null;

const UPSTREAM_ISSUES_EMPTY_CONTENT_MESSAGE = 'No upstream tests detected';
const NO_ISSUES_MESSAGE = 'No upstream issues detected';

const defaultFiltersConfig: Filter.FilterOptions = {
  order: '-last_run_at',
  page: 1,
  page_size: 100,
  sortColumn: 'latestRunAt',
  sortDirection: 'ascending',
};

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  name: 'search_name',
};

const sortConfig = {
  columnName: 'column_name',
  distance: 'distance,-popularity',
  latestRunAt: 'last_run_at',
  sourceType: 'source_type',
  tableName: 'table_name',
  testName: 'test_name',
};

export const FILTER_CONFIG = {
  defaultFiltersConfig,
  defaultOrder: '-last_run_at',
  searchConfig,
  sortConfig,
};

interface UpstreamIssuesTableProps {
  guid: string;
}

const UpstreamIssuesTable: React.FC<UpstreamIssuesTableProps> = ({ guid }) => {
  const { changePage, filter, globalSearch, sort } = Filter.useUpdateFilters(
    FILTER_CONFIG.defaultFiltersConfig,
    FILTER_CONFIG.searchConfig,
    FILTER_CONFIG.sortConfig,
    FILTER_CONFIG.defaultOrder,
  );

  const history = useHistory();
  const { itemId } = useParams<{ itemId: string }>();

  const [searchVisible, setSearchVisible] = useState(false);
  const [currColumn, setCurrColumn] = useState<string | undefined>(itemId);
  const initialResultsLengthRef = useRef<number | null>(null);
  const filtersWithParams = Filter.setParams(filter);
  const { dataSourceGuid } = useMetadataObjectContext();
  const { dataSources, organization } = useUserContext();
  const isDataSourceEditable = Boolean(dataSources?.[dataSourceGuid ?? '']?.settings?.isEditable);
  const showColumnCategoryTags = organization?.settings?.showColumnCategoryTags ?? false;

  const { data, isError, isLoading } = useFetchDataQualityUpstreamIssues(guid, {
    params: {
      ...filtersWithParams,
      order: filtersWithParams.order as GetDataQualityUpstreamIssuesListPathParams['order'],
    },
  });

  const initialResultsLength = useMemo(() => {
    if (data?.results && initialResultsLengthRef.current === null) {
      initialResultsLengthRef.current = data?.results.length;
    }
    return initialResultsLengthRef.current;
  }, [data?.results]);

  const columns: ColumnConfig<DataQualityTestModel>[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (props: Cell<DataQualityTestModel>) => {
        const {
          row: { original },
        } = props;

        return (
          <DataSourceTypeCell {...props} dataSourceType={original.dataTypes?.dataSourceType} />
        );
      },
      Header: 'Source',
      accessor: 'sourceType',
      id: 'sourceType',
      width: 115,
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (props: Cell<DataQualityTestModel> & { globalFilter: string }) => {
        const {
          globalFilter,
          row: {
            original: { table },
          },
        } = props;

        return (
          <LinkedCell
            customUrl={`${table.routePath}/data-quality`}
            globalFilter={globalFilter}
            item={table}
            itemName={table.name}
            showIcon
            showNameTooltip
          />
        );
      },
      Header: 'Table',
      accessor: (d) => d.table?.name,
      id: 'tableName',
      width: '120%',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (props: Cell<DataQualityTestModel>) => {
        const {
          row: {
            original: { column: columnData },
          },
        } = props;

        return (
          <MonitorColumnCell
            columnData={columnData}
            openColumnsPage={() => {
              setCurrColumn(columnData?.guid);
            }}
          />
        );
      },
      Header: 'Column',
      accessor: (d) => d.column?.name,
      id: 'columnName',
      width: '100%',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (props: Cell<DataQualityTestModel>) => {
        const {
          row: {
            original: { dataQualityObject },
          },
        } = props;

        return (
          <Box alignItems="center" compDisplay="flex">
            {dataQualityObject?.obj && (
              <>
                <TableItem
                  {...props}
                  item={dataQualityObject.obj}
                  name={dataQualityObject.obj.name}
                  noLink
                  showIcon
                />
                {dataQualityObject.obj && 'externalUrl' in dataQualityObject.obj && (
                  <OpenInNewTabButton url={dataQualityObject.obj.externalUrl} />
                )}
              </>
            )}
          </Box>
        );
      },
      Header: `Test ${wrapString(data?.count)}`,
      accessor: (d) => d.dataQualityObject?.obj?.name,
      disableHiding: true,
      id: 'testName',
      width: '280%',
    },
    {
      Cell: (props: Cell<DataQualityTestModel>) => {
        const {
          row: { original },
        } = props;
        return <LineageDistanceCell {...props} distance={original.distance} />;
      },
      Header: () => <LineageDistanceHeader />,
      accessor: (d) => d.distance,
      dropdownCheckboxLabel: 'Lineage Distance',
      id: 'distance',
      width: 165,
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: Cell<DataQualityTestModel>) => <Status status={row.original.status} />,
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
      id: 'status',
      width: 80,
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: Cell<DataQualityTestModel>) => (
        <DateTime datetime={row.original.latestRunAt} format="fullDateFormat" />
      ),
      Header: 'Last Run',
      accessor: (d) => d?.latestRunAt,
      id: 'latestRunAt',
      sortType: sortDates,
      width: 155,
    },
  ];

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;
  const getRowId = useCallback((row: Partial<any>) => row.guid!, []);

  if (isError) {
    return <TabError />;
  }

  if (initialResultsLength === 0 && data?.non_failing_data_quality_tests_exist) {
    return <ImageMessage message={NO_ISSUES_MESSAGE} src="/images/success.png" />;
  }

  if (initialResultsLength === 0) {
    return <EmptyContent message={UPSTREAM_ISSUES_EMPTY_CONTENT_MESSAGE} />;
  }

  return (
    <>
      <TableStyled>
        <Table
          basic="very"
          changePage={changePage}
          className="table-full"
          columns={columns}
          compact
          data={data?.results ?? []}
          disableRowSelect
          getRowId={getRowId}
          initialState={{
            sortBy: [
              {
                desc: false,
                id: 'latestRunAt',
              },
            ],
          }}
          loading={isLoading}
          manualGlobalFilter
          manualPagination
          manualSortBy
          selectable
          setGlobalFilter={globalSearch}
          setSortBy={sort}
          showFilter={searchVisible}
          showGlobalFilterV1
          sortable
          stickyHeader
          toggleFilter={() => setSearchVisible((prev) => !prev)}
          totalPages={totalPages}
          unstackable
        />
      </TableStyled>
      {currColumn && (
        <ColumnPage
          dsGuid={dataSourceGuid}
          guid={currColumn}
          hideCategoryTags={!showColumnCategoryTags}
          isDataSourceEditable={isDataSourceEditable}
          onClose={() => {
            history.push(`/tables/${guid}/data-quality/upstream-issues`);
            setCurrColumn(undefined);
          }}
        />
      )}
    </>
  );
};

export default UpstreamIssuesTable;

import { UsageTypeType } from '@api/lineage/types';
import { NewLineageItemSourceEdges, NewLineageItemTargetEdges } from '@api/openapi.generated';
import { InputNodesById } from '@components/Explore.v1/Explore.types';

import { NodesById } from '../useCreateNodesEdges/algorithm/types';
import traversal from '../useCreateNodesEdges/utils/traversal';

export const getAllInputNodeParents = (
  nodeKey: string,
  inputNodesById: InputNodesById,
): InputNodesById => {
  const findParentNodes = (key: string, parents: InputNodesById): InputNodesById => {
    const parentNode = inputNodesById[key];
    if (!parentNode || !parentNode.metadata.parent_guid) {
      return parents;
    }

    const parent = inputNodesById?.[parentNode.metadata.parent_guid];
    if (!parent) {
      return parents;
    }

    return findParentNodes(parentNode.metadata.parent_guid, { ...parents, [parent.key]: parent });
  };

  return findParentNodes(nodeKey, {});
};

interface MergeRelevantColumnLineageStateParams {
  newNodes: NodesById;
  previousNodes: NodesById;
}

export const mergeRelevantColumnLineageState = ({
  newNodes,
  previousNodes,
}: MergeRelevantColumnLineageStateParams) => {
  const nodesById: NodesById = {};

  Object.entries(newNodes).forEach(([key, value]) => {
    const currentPreviousNode = previousNodes[key];

    if (currentPreviousNode) {
      nodesById[key] = {
        ...value,
        data: {
          ...value.data,
          childrenSearchText: currentPreviousNode?.data?.childrenSearchText,
          isSearchEnabled: currentPreviousNode?.data?.isSearchEnabled,
          shownChildrenCount: Math.max(
            currentPreviousNode?.data?.shownChildrenCount ?? 0,
            value.data.shownChildrenCount ?? 0,
          ),
        },
      };
    } else {
      nodesById[key] = value;
    }
  });

  return nodesById;
};

interface GetTableToColumnEdgesParams {
  columnId: string;
  inputNodesById: InputNodesById;
}

const getTableToColumnEdges = ({ columnId, inputNodesById }: GetTableToColumnEdgesParams) => {
  const node = inputNodesById[columnId];
  return [columnId, String(node.metadata.parent_guid)];
};

export const createColumnLevelInput = ({
  columnId,
  inputNodesById,
  parentKey,
}: {
  columnId: string;
  inputNodesById: InputNodesById;
  parentKey: string;
}) => {
  const newInput: InputNodesById = {};
  const visitedNodes = new Set<string>([]);
  const tablesSources: Record<string, string[]> = {};
  const tablesTargets: Record<string, string[]> = {};

  traversal({
    action: ({ direction, id, isInitialNode, parentId }) => {
      if (isInitialNode || direction) {
        const currentNode = inputNodesById[id];
        const isCurrentNodeATable = currentNode.node_type === 'table';
        newInput[id] = currentNode;
        visitedNodes.add(id);

        if (!isInitialNode && parentId && id !== parentId) {
          const { source_edges: sourceEdges = {}, target_edges: targetEdges = {} } = currentNode;

          const edgesToGetUsage = direction === 'right' ? sourceEdges : targetEdges;

          const usages: Array<UsageTypeType> = edgesToGetUsage[parentId]?.usage_type ?? [];
          const validUsages = usages.filter((usage) => usage);

          newInput[id].usage = validUsages;
        }

        const allParents = getAllInputNodeParents(id, inputNodesById);
        Object.keys(allParents).forEach((key) => {
          if (!newInput[key]) {
            newInput[key] = {
              ...allParents[key],
              columns: [],
              source_edges: isCurrentNodeATable ? allParents[key].source_edges : {},
              target_edges: isCurrentNodeATable ? allParents[key].target_edges : {},
            };
          }
        });

        if (!isInitialNode && parentId && currentNode?.metadata.parent_guid) {
          const originNode = inputNodesById[parentId];
          const isOriginNodeAColumn = originNode.node_type === 'column';
          const originNodeParent = originNode.metadata?.parent_guid ?? '';
          const objectToPush = direction === 'right' ? tablesSources : tablesTargets;
          const tableId = isCurrentNodeATable ? id : currentNode.metadata.parent_guid;
          const edgesToPush =
            isCurrentNodeATable && isOriginNodeAColumn
              ? getTableToColumnEdges({ columnId: parentId, inputNodesById })
              : [originNodeParent];

          objectToPush[tableId] = [...(objectToPush[tableId] ?? []), ...edgesToPush];
        }
      }
    },
    calculateStraightPath: true,
    initialNodeId: columnId,
    nodesById: inputNodesById,
    skipColumns: false,
  });

  Object.entries(tablesTargets).forEach(([key, value]) => {
    const newTargetEdges: NewLineageItemTargetEdges = {};

    value.forEach((targetEdgeKey) => {
      const targetEdge = inputNodesById[key]?.target_edges?.[targetEdgeKey];
      if (targetEdge) {
        newTargetEdges[targetEdgeKey] = targetEdge;
      }

      const oppositeEdge = inputNodesById[targetEdgeKey]?.source_edges?.[key];
      if (oppositeEdge && newInput[targetEdgeKey].source_edges) {
        newInput[targetEdgeKey] = {
          ...newInput[targetEdgeKey],
          source_edges: {
            ...(newInput[targetEdgeKey].source_edges ?? {}),
            [key]: oppositeEdge,
          },
        };
      }
    });

    newInput[key].target_edges = newTargetEdges;
  });

  Object.entries(tablesSources).forEach(([key, value]) => {
    const newSourceEdges: NewLineageItemSourceEdges = {};

    value.forEach((sourceEdgeKey) => {
      const sourceEdge = inputNodesById[key]?.source_edges?.[sourceEdgeKey];
      if (sourceEdge) {
        newSourceEdges[sourceEdgeKey] = sourceEdge;
      }

      const oppositeEdge = inputNodesById[sourceEdgeKey]?.target_edges?.[key];
      if (oppositeEdge && newInput[sourceEdgeKey].target_edges) {
        newInput[sourceEdgeKey] = {
          ...newInput[sourceEdgeKey],
          target_edges: {
            ...(newInput[sourceEdgeKey].target_edges ?? {}),
            [key]: oppositeEdge,
          },
        };
      }
    });

    newInput[key].source_edges = newSourceEdges;
  });

  Array.from(visitedNodes).forEach((nodeKey) => {
    const node = inputNodesById[nodeKey];
    const { metadata } = node;
    const parentGuid = metadata?.parent_guid;

    if (node.node_type === 'table') {
      newInput[nodeKey].columns = [];
    } else if (parentGuid && newInput[parentGuid]) {
      newInput[parentGuid].columns = [...(newInput[parentGuid]?.columns ?? []), nodeKey];
    }
  });

  const originalParentNode = inputNodesById[parentKey];
  const originalParentNodeColumns = originalParentNode?.columns ?? [];
  originalParentNodeColumns.forEach((columnKey) => {
    const isVisited = visitedNodes.has(columnKey);
    if (!isVisited) {
      newInput[columnKey] = { ...inputNodesById[columnKey], shouldSkipEdgesCalculation: true };
    }
  });
  if (!newInput[parentKey]) {
    newInput[parentKey] = originalParentNode;
  }
  newInput[parentKey].columns = originalParentNodeColumns;

  return newInput;
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useFetchLineageNew } from '@api/lineage';
import createStacks from '@components/Explore.v1/useCreateNodesEdges/utils/createStacks';
import { useExplore } from '@components/Explore.v1/useExplore';
import useGetConfigQueryParams from '@components/Explore.v1/useGetConfigQueryParams';
import useUserTracking from '@components/Explore.v1/useUserTracking';
import { renderInfoToast } from '@components/Toast';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import { LineageInteractions } from '../Explore.types';
import parseLineageNodes from '../useCreateNodesEdges/algorithm/parseLineageNodes';
import { EdgesById, NodesById } from '../useCreateNodesEdges/algorithm/types';
import { calculateUsageTypeFilter } from '../useCreateNodesEdges/useCreateNodesEdges';
import {
  LineageExpansionType,
  LineageModeChangeTrigger,
} from '../useExplore/Explore.context.types';
import { useUndoRedo } from '../useUndoRedo';

import {
  ExpandLineageParams,
  ExpansionConfig,
  SaveNewLineageParams,
  SaveNewLineageStateParams,
  ToggleColumnLevelLineageParams,
  UseExpandLineageParams,
} from './useExpandLineage.types';
import {
  mergeCalculatedNodesData,
  mergeRawNodesRelations,
  removeNodes,
} from './useExpandLineage.utils';

const DISABLED_REQUEST_CONFIG: ExpansionConfig = {
  enabled: false,
  params: {},
  removeUnrelatedNodesOnExpand: false,
  trigger: LineageModeChangeTrigger.Click,
  type: LineageExpansionType.OpenOneLevelDownstreamLineage,
};

const useExpandLineage = ({ guid, nodeKey, origin = 'table' }: UseExpandLineageParams) => {
  const { track } = useUserTracking();
  const { guid: originalGuid } = useParams<{ guid: string }>();
  const [expansionConfig, setExpansionConfig] = useState<ExpansionConfig>(DISABLED_REQUEST_CONFIG);
  const { enabled, params, trigger: expansionTrigger } = expansionConfig;
  const expansionConfigRef = useRef(expansionConfig);
  expansionConfigRef.current = expansionConfig;

  const { enableBorderlineEdges, enableColumnLevelTraversal, enableHorizontalGroups } =
    useGetConfigQueryParams();

  const {
    columnLevelLineageRootKey,
    initialPosition,
    inputNodesById: currentInputNodesById,
    isCollapseAllButtonEnabled,
    isColumnLevelLineage,
    nodesById: currentNodesById,
    oneDirectionParseExceptions,
    previousTableLineageState,
    selectedUsageTypesState,
    setBiggestConflictEndPerStack,
    setEdgesById,
    setInputNodesById,
    setIsColumnLevelLineage,
    setLoadingState,
    setNextUserInteraction,
    setNodesById,
    setNodesByStack,
    setOneDirectionParseExceptions,
    setStacksData,
    stackData: currentStacksData,
  } = useExplore();

  const { saveExploreState } = useUndoRedo();

  const setLoading = useCallback(
    (isLoading: boolean) => {
      setLoadingState({ enabled: isLoading, type: 'expansion' });
    },
    [setLoadingState],
  );

  const clearExpandState = useCallback(() => {
    setExpansionConfig(DISABLED_REQUEST_CONFIG);
    setLoading(false);
  }, [setLoading]);

  const disableColumnLevelLineage = (isClosing: boolean) => {
    if (!isClosing) {
      setIsColumnLevelLineage(false);
    }
  };

  const getCurrentNodesData = (isClosing: boolean) => {
    if (isColumnLevelLineage && !isClosing) {
      return {
        edgesById: previousTableLineageState.stackData?.edgesById as EdgesById,
        nodesById: previousTableLineageState.stackData?.nodesById as NodesById,
      };
    }

    return {
      edgesById: currentStacksData?.edgesById as EdgesById,
      nodesById: currentStacksData?.nodesById as NodesById,
    };
  };

  const getCurrentInputNodesById = () => {
    return isColumnLevelLineage ? previousTableLineageState.inputNodesById : currentInputNodesById;
  };

  const toggleColumnLevelLineage = ({
    isClickedNodeColumnSelected,
    isClosing,
    isColumnAction,
  }: ToggleColumnLevelLineageParams) => {
    if (isColumnAction && nodeKey && !isClosing) {
      setNextUserInteraction({
        payload: {
          expansionTrigger,
          nodeKey:
            columnLevelLineageRootKey && isClickedNodeColumnSelected
              ? columnLevelLineageRootKey
              : nodeKey,
        },
        type: LineageInteractions.ToggleColumnLevelLineage,
      });
    }
  };

  const saveNewLineageState = ({
    isColumnAction,
    newBiggestConflictEndPerStack,
    newEdgesById,
    newInputNodesById,
    newNodesById,
    newNodesByStack,
    newStacksData,
  }: SaveNewLineageStateParams) => {
    /*
     * Skip saveExploreState if it is a column level lineage expansion.
     * In column level lineage, we first load the table level and then enable the column level.
     * If we save the state here, it will save the table level.
     * The new state will be saved in the useColumnLevelLineage hook.
     */
    if (!isColumnAction) {
      saveExploreState({
        biggestConflictEndPerStack: newBiggestConflictEndPerStack,
        edgesById: newEdgesById,
        initialPosition,
        inputNodesById: newInputNodesById,
        isCollapseAllButtonEnabled,
        isColumnLevelLineage,
        nodesById: newNodesById,
        nodesByStack: newNodesByStack,
        stackData: newStacksData,
      });
    }
    setInputNodesById(newInputNodesById);
    setStacksData(newStacksData);
    setNodesByStack(newNodesByStack);
    setEdgesById(newEdgesById);
    setNodesById(newNodesById);
    setBiggestConflictEndPerStack(newBiggestConflictEndPerStack);
  };

  const fitLineageView = (
    shouldRemoveUnrelatedNodes: boolean,
    isColumnAction: boolean,
    expansionType: LineageExpansionType,
  ) => {
    if (
      shouldRemoveUnrelatedNodes ||
      (!expansionConfig.removeUnrelatedNodesOnExpand && !isColumnAction)
    ) {
      setNextUserInteraction({
        payload: {
          duration: [
            LineageExpansionType.OpenOneLevelDownstreamLineage,
            LineageExpansionType.OpenOneLevelUpstreamLineage,
          ].includes(expansionType)
            ? 1200
            : undefined,
        },
        type: LineageInteractions.FitLineageView,
      });
    }
  };

  const saveNewLineage = ({
    expansionType,
    isClickedNodeColumnSelected,
    isClosing,
    isColumnAction,
    shouldRemoveUnrelatedNodes,
    ...dataParams
  }: SaveNewLineageParams) => {
    saveNewLineageState({ ...dataParams, isColumnAction });
    toggleColumnLevelLineage({ isClickedNodeColumnSelected, isClosing, isColumnAction });
    clearExpandState();
    fitLineageView(shouldRemoveUnrelatedNodes, isColumnAction, expansionType);
  };

  const expandLineage = ({
    beParsingTime,
    data,
    isClosing = false,
    previousCalculationStartTime,
    type: expansionType,
  }: ExpandLineageParams) => {
    const startTime = previousCalculationStartTime ?? Date.now();
    const shouldUseColumnLevelLineage = isClosing && isColumnLevelLineage;
    const isColumnAction = origin === 'column';
    const isPivotOriginalNode = nodeKey === originalGuid;

    const shouldRemoveUnrelatedNodes =
      !isColumnAction && !isPivotOriginalNode && expansionConfig.removeUnrelatedNodesOnExpand;

    const previousInputNodesById = getCurrentInputNodesById();

    const newInputNodesById = isClosing
      ? data
      : mergeRawNodesRelations({
          newNodes: data ?? {},
          pivotNodeKey: nodeKey,
          previousNodes: previousInputNodesById ?? {},
          shouldRemoveUnrelatedNodes,
        });

    const columnNodeParent = newInputNodesById[nodeKey]?.metadata?.parent_guid ?? '';

    // If the node is a column, we need to set the starting table to the parent table of the column
    let startingTableId = isColumnAction ? columnNodeParent : nodeKey;
    let clickedNodeStackId = currentNodesById[nodeKey]?.stackedAt ?? 0;

    const newDirectionParseExceptions = { ...oneDirectionParseExceptions };

    /*
     * Handling node parsing for one upstream/downstream level:
     * If the originalGuid is visible, use it as the starting point for parsing.  Otherwise, we would use the clicked node as the starting point.
     *
     * For the one upstream/downstream level feature, add new table nodes to oneDirectionParseExceptions to force placing those nodes on the closest left and right sides.
     * @see bfsTraversal {@link /src/components/Explore.v1/useCreateNodesEdges/utils/traversal.ts#L132} comments to understand why it places nodes at a different place.
     */
    if (newInputNodesById[originalGuid] && !expansionConfig.removeUnrelatedNodesOnExpand) {
      startingTableId = originalGuid;
      clickedNodeStackId = currentNodesById[originalGuid]?.stackedAt ?? 0;

      Object.keys(data ?? {}).forEach((itemId) => {
        if (data[itemId].node_type === 'table') {
          newDirectionParseExceptions[itemId] = true;
        }
      });

      setOneDirectionParseExceptions(newDirectionParseExceptions);
    }

    const newStacksData = createStacks({
      initialStackId: clickedNodeStackId,
      inputNodesById: newInputNodesById,
      oneDirectionParseExceptions: newDirectionParseExceptions,
      options: {
        enableColumnEdges: shouldUseColumnLevelLineage,
        enableHorizontalGroups,
        enableTableEdges: !shouldUseColumnLevelLineage,
        openAll: shouldUseColumnLevelLineage,
        pivotToSkipCollapsing: nodeKey,
      },
      startingTableId,
    });

    const {
      edgesById: newCalculatedEdges,
      nodesById: newCalculatedNodes,
      stackGroups: newStackGroups,
    } = newStacksData;

    const { edgesById: previousEdges, nodesById: previousNodes } = getCurrentNodesData(isClosing);

    disableColumnLevelLineage(isClosing);

    const { edgesById, nodesById } = mergeCalculatedNodesData({
      isClosing,
      newEdges: newCalculatedEdges,
      newNodes: newCalculatedNodes,
      previousEdges,
      previousNodes,
      shouldRemoveUnrelatedNodes,
    });

    const {
      biggestConflictEndPerStack: newBiggestConflictEndPerStack,
      edgesById: newEdgesById,
      nodesById: newNodesById,
      nodesByStack: newNodesByStack,
    } = parseLineageNodes({
      edgesById,
      initialPosition,
      isColumnLevelLineage,
      nodesById,
      stackGroups: newStackGroups,
    });

    if (
      [
        LineageExpansionType.OpenOneLevelDownstreamLineage,
        LineageExpansionType.OpenAllDownstream,
      ].includes(expansionType)
    ) {
      newInputNodesById[nodeKey].hideDownstreamButton = true;
      nodesById[nodeKey].data.hideDownstreamButton = true;
      newInputNodesById[nodeKey].hideUpstreamButton =
        currentInputNodesById?.[nodeKey].hideUpstreamButton;
      nodesById[nodeKey].data.hideUpstreamButton =
        currentNodesById[nodeKey].data.hideUpstreamButton;

      if (newNodesById[nodeKey]) {
        newNodesById[nodeKey].data.hideDownstreamButton = true;
      }
    } else if (
      [
        LineageExpansionType.OpenOneLevelUpstreamLineage,
        LineageExpansionType.OpenAllUpstream,
      ].includes(expansionType)
    ) {
      newInputNodesById[nodeKey].hideUpstreamButton = true;
      nodesById[nodeKey].data.hideUpstreamButton = true;
      newInputNodesById[nodeKey].hideDownstreamButton =
        currentInputNodesById?.[nodeKey].hideDownstreamButton;
      nodesById[nodeKey].data.hideDownstreamButton =
        currentNodesById[nodeKey].data.hideDownstreamButton;

      if (newNodesById[nodeKey]) {
        newNodesById[nodeKey].data.hideUpstreamButton = true;
      }
    } else if ([LineageExpansionType.CloseAllDownstream].includes(expansionType)) {
      newInputNodesById[nodeKey].hideDownstreamButton = false;
      nodesById[nodeKey].data.hideDownstreamButton = false;
      newInputNodesById[nodeKey].hideUpstreamButton =
        currentInputNodesById?.[nodeKey].hideUpstreamButton;
      nodesById[nodeKey].data.hideUpstreamButton =
        currentNodesById[nodeKey].data.hideUpstreamButton;

      if (newNodesById[nodeKey]) {
        newNodesById[nodeKey].data.hideDownstreamButton = false;
      }
    } else if ([LineageExpansionType.CloseAllUpstream].includes(expansionType)) {
      newInputNodesById[nodeKey].hideUpstreamButton = false;
      nodesById[nodeKey].data.hideUpstreamButton = false;
      newInputNodesById[nodeKey].hideDownstreamButton =
        currentInputNodesById?.[nodeKey].hideDownstreamButton;
      nodesById[nodeKey].data.hideDownstreamButton =
        currentNodesById[nodeKey].data.hideDownstreamButton;

      if (newNodesById[nodeKey]) {
        newNodesById[nodeKey].data.hideUpstreamButton = false;
      }
    }

    const isClickedNodeColumnSelected =
      isColumnAction && Boolean(currentNodesById[nodeKey]?.data?.isSelected);

    saveNewLineage({
      expansionType,
      isClickedNodeColumnSelected,
      isClosing,
      isColumnAction,
      newBiggestConflictEndPerStack,
      newEdgesById,
      newInputNodesById,
      newNodesById,
      newNodesByStack,
      newStacksData: {
        ...newStacksData,
        edgesById,
        nodesById,
      },
      shouldRemoveUnrelatedNodes,
    });

    const endTime = Date.now();
    const feParsingTime = endTime - startTime;

    track(SegmentTrackEventName.LineageExpansionLoaded, {
      beParsingTime,
      feParsingTime,
      nodesCount: Object.keys(newNodesById).length,
      originGuid: guid,
      originKey: nodeKey,
      originType: origin,
      totalParsingTime: feParsingTime + (beParsingTime ?? 0),
      type: expansionType ?? expansionTrigger,
    });
  };

  const { data: apiData } = useFetchLineageNew(guid, {
    enabled,
    onSuccess: (response) => {
      if (Object.keys(response?.data).length === 0) {
        renderInfoToast('No additional lineage to load.');
      }
    },
    params: {
      ...params,
      usage_type: calculateUsageTypeFilter(selectedUsageTypesState.usageTypes),
    },
  });

  useEffect(() => {
    if (apiData) {
      const { data, requestMetadata } = apiData;
      expandLineage({
        beParsingTime: requestMetadata.duration,
        data,
        type: expansionConfigRef.current.type,
      });
    }
  }, [apiData]);

  const openAllDownstream = () => {
    setLoading(true);
    setOneDirectionParseExceptions({});
    setExpansionConfig({
      enabled: true,
      params: {
        direction: 'downstream',
        include_borderline_edges: enableBorderlineEdges,
        max_depth: 'max',
        mode: 'all',
        relevant_lineage: enableColumnLevelTraversal,
      },
      removeUnrelatedNodesOnExpand: true,
      trigger: LineageModeChangeTrigger.OpenAllDownstream,
      type: LineageExpansionType.OpenAllDownstream,
    });
  };

  const openAllUpstream = () => {
    setLoading(true);
    setOneDirectionParseExceptions({});
    setExpansionConfig({
      enabled: true,
      params: {
        direction: 'upstream',
        include_borderline_edges: enableBorderlineEdges,
        max_depth: 'max',
        mode: 'all',
        relevant_lineage: enableColumnLevelTraversal,
      },
      removeUnrelatedNodesOnExpand: true,
      trigger: LineageModeChangeTrigger.OpenAllUpstream,
      type: LineageExpansionType.OpenAllUpstream,
    });
  };

  const closeAllDownstream = () => {
    setLoading(true);
    if (currentInputNodesById) {
      const startTime = Date.now();
      const newNodes = removeNodes({
        direction: 'downstream',
        isColumnLevelLineage,
        nodeKey,
        nodesById: currentInputNodesById,
      });
      expandLineage({
        data: newNodes,
        isClosing: true,
        previousCalculationStartTime: startTime,
        type: LineageExpansionType.CloseAllDownstream,
      });
    }
  };

  const closeAllUpstream = () => {
    setLoading(true);
    if (currentInputNodesById) {
      const startTime = Date.now();
      const newNodes = removeNodes({
        direction: 'upstream',
        isColumnLevelLineage,
        nodeKey,
        nodesById: currentInputNodesById,
      });
      expandLineage({
        data: newNodes,
        isClosing: true,
        previousCalculationStartTime: startTime,
        type: LineageExpansionType.CloseAllUpstream,
      });
    }
  };

  const openDownstreamLineage = ({
    eventTrigger,
    maxDepth,
  }: {
    eventTrigger: LineageModeChangeTrigger;
    maxDepth: number;
  }) => {
    setLoading(true);
    setExpansionConfig({
      enabled: true,
      params: {
        direction: 'downstream',
        include_borderline_edges: enableBorderlineEdges,
        max_depth: maxDepth,
        mode: 'all',
        relevant_lineage: enableColumnLevelTraversal,
      },
      removeUnrelatedNodesOnExpand: false,
      trigger: eventTrigger,
      type: LineageExpansionType.OpenOneLevelDownstreamLineage,
    });
  };

  const openUpstreamLineage = ({
    eventTrigger,
    maxDepth,
  }: {
    eventTrigger: LineageModeChangeTrigger;
    maxDepth: number;
  }) => {
    setLoading(true);
    setExpansionConfig({
      enabled: true,
      params: {
        direction: 'upstream',
        include_borderline_edges: enableBorderlineEdges,
        max_depth: maxDepth,
        mode: 'all',
        relevant_lineage: enableColumnLevelTraversal,
      },
      removeUnrelatedNodesOnExpand: false,
      trigger: eventTrigger,
      type: LineageExpansionType.OpenOneLevelUpstreamLineage,
    });
  };
  return {
    closeAllDownstream,
    closeAllUpstream,
    openAllDownstream,
    openAllUpstream,
    openDownstreamLineage,
    openUpstreamLineage,
  };
};

export default useExpandLineage;
